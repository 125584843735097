.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
}

.login-form{
    width: 50%;
    padding: 10px;
    border:1px solid gray;
}