
.timeslot{
    display: flex;
    flex-direction: row;
    background-color: #e6f5eb;
    height: 20px;
    margin: 3px 2px 2px 2px;
    align-items: center;
    cursor: pointer;

}

.time-slots-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
   
}

.detailed-timeslot{
    display: flex;
    flex-direction: row;
    background-color: #e6f5eb;
    height: 20px;
    width: 68%;
    margin: 3px 2px 2px 2px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

}

.detailed-time-slots-container{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
   
}

/* .timeslot{
    display: flex;
    flex-direction: row;
    width:50%;
    align-items: center;
    height: 50px;

} */

 .tdate{
    font-size:12px;
    font-weight: bold;
}

 .ttime{
    text-align: center;
    color:  #5264ab;
    font-weight: bold;   
    margin-left: 2px;
    font-size:12px;
}

 /* .tradio{
    flex-grow: 1;
    text-align: center;
    
    height: 100%;
} */

.timeslot:hover{
    background-color: #a1dbb4;
}

