
.appointment {
    display: flex;
    flex-direction: row;
    height: 40px;
    cursor: pointer;
    position: relative;
    margin: 2px;
   
}

.deny{
    background-color: #f2bfc1;
}

.approve{
    background-color: #e8f7e6;
}

.deactive{
    background-color: #f9f9f9;
    color: #bfc7c4;
}

.appointment-overlay {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    background:  linear-gradient(to right, rgba(255, 255, 255,0) 0%, rgb(255, 255, 255) 100%);
}

.appointment-overlay-row {
    display: flex;
    padding: 4px;
    flex-direction:column ;
    justify-content: center;
    color:  #5c5c5c;
    
}

.appointment-overlay-row :hover{
    color:  #000000;
    transition: all .4s ease;
    -webkit-transition: all .4s 
    
}


.appointment:active {
    background-color: rgb(180, 170, 170);
}

.appointments {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.appointmentDetails{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}



.appointmentDetails .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}



.appointmentDetails .item-column-center{
    display: flex;
    align-items: center;
    flex-direction: column;
   
}


.appointmentDetails .item h1,h2,h3,h4,h5{
    margin: 0;
    padding: 0;
}



.title {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    
}

.subTitle {
    font-size: 12px;
    color: gray;
  
}

.date {
    align-self: start;
    font-size: 10px;
    text-align: center;
    
}

.date p {
    margin: 0;
}

.appointment .column {
    height: 40px;
    justify-content: center;
    margin:0 7px 0 7px;
}


.appointment .avatar-container{
    width:45px;
    height:45px;
   
}

.appointment .column div {
    
    padding: 0;
}

.appointment .column div p {
    margin: 0;
}

.modal-content .info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    width: 100%;
}

.modal-content .info .left{
    padding: 5px;
    width: 40%;
    
  }

  .modal-content .info .right{
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 65%;
    
    
  }

  .current-appointment-box{
      padding:20px;
      border-radius: 10%;
      background-color: #e6f5eb;
      cursor: pointer;
  }

  .current-appointment-box:hover{
    background-color: #a1dbb4;

}