.userInfoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    min-height: 100vh;
   
}

.appointment-info{
    width: 70%;
    padding: 10px;
    margin-top:15px ;
    text-align: center;
    border-radius: 15px;
}

.intro{
    width: 70%;
    padding: 10px;
    margin-top:15px ;
    background-color: rgb(230, 230, 230);
    border-radius: 15px;
}

.intro p, strong{
    margin: 4px;
}

.phoneContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.userInfo-form{
    width: 70%;
    padding: 10px;
   
}

.userConfrimContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;  
    height: 70vh; 
}

.appointment-ticket{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px; 
    background-color: antiquewhite; 
    border-radius: 15px;
}

.appointment-ticket .column{
    padding: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: red; 
}

.appointment-ticket .row{
    display: flex;
    flex-direction: column;
    background-color: sandybrown;
}
.appointment-ticket-title{
    font-weight: bold;
    color: gray;
}