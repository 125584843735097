body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
   
}

.main {
    display: flex;
    flex-direction: row;
    min-height: 70vh;
    background-color: #ebedeef3;
}

.leftPanel {
    display: flex;
    border-right: 1px solid rgba(241, 238, 238, 0.856);
    flex-direction: column;
    min-height: 200px;
    width: 35%;
}

.rightPanel {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    width:65% ;
}

.scroll-panel{
overflow-y: scroll;
height:calc(100vh - 40px);
}



@media only screen and (max-width: 768px) {
    
    

    .tab-menu{
        display: flex;
        flex-direction: column;
        border-radius: 50px;
        margin: 5px 0 5px 0;
        
    }
    
    .tab-menu-item{
        display: flex;
        flex-direction: row;
        padding: 5px;
        cursor: pointer;
     
        align-items: flex-start;
        margin-left: 5px;
    
        -webkit-transition: all .4s;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .tab-menu-item p{
        font-size: 10px ;
        font-weight: bold;
        color: gray;
        margin:4px 0 0 0;  
        padding: 0;
        margin-left: 5px;
    }
} 

@media only screen and (min-width: 768px) {
    .panel-header{
        background-color: white;
        display: flex;
        flex-direction: column;
        min-height: 50px;
        position: sticky;
        top: 0;
        z-index: 1000;
        position: -webkit-sticky;
        align-items: center;
        padding-left: 5px;
       
    }

    .tab-menu{
        display: flex;
        flex-direction: row;
        border-radius: 50px;
        margin: 5px 0 5px 0;
    }
    
    .tab-menu-item{
        display: flex;
        flex-direction: column;
        padding: 5px;
        cursor: pointer;
     
        align-items: center;
        margin-left: 5px;
    
        -webkit-transition: all .4s;
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }
    .tab-menu-item p{
        font-size: 10px ;
        font-weight: bold;
        color: gray;
        margin:4px 0 0 0;  
        padding: 0;
    }
} 


.tab-menu-item-icon{
    color:  #cecece;
}

.tab-menu-item:hover .tab-menu-item-icon {
    color:  #858585;
    transition: all .4s ease;
    -webkit-transition: all .4s 
}

 .active{
    color:  #0e7c33 !important;
    transition: all .4s ease;
    -webkit-transition: all .4s 
}

.active + p{
    color:  #000000 !important;
    transition: all .4s ease;
  

}



.search-container{
    width: 50%;
    height: 35px;
    border: 1px solid rgb(230, 224, 224);
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    align-items: center;
    background-color: rgb(240, 240, 240);
    margin-top: 5px;
}

.search-container input{
    width:75%;
    background-color: rgb(240, 240, 240);
    height: 88%;
    border: none;
    padding-left: 5px;
    border-radius: 50px;
    margin-left:5px;
}























.search-container input:focus{
    outline: none;
}

.search-icon{
    cursor: pointer;
}


.new-contact{
   
    display: flex;
    flex-direction: row;
    min-height: 50px;
   
}

.contact-item{
    padding: 5px;
    cursor: pointer;
    width: 7%;
}

/* scroll bar styles*/
/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgb(245, 245, 245);
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgb(167, 166, 165);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color:rgb(240, 239, 238);;
    border-radius: 20px;
    border: 3px solid gray
  }
  

body::-webkit-scrollbar-thumb {
    background-color:rgb(240, 239, 238);;
    border-radius: 20px;
    border: 3px solid rgb(245, 245, 245);
  }



.header {
    display: flex;
    flex-direction: row;
    background-color: #f1f2f3;
    justify-content: space-between;
    align-items: center;
}

.header-nav{
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;
}

.avatar {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}
.header-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
   
}



.footer {
    display: flex;
    flex-direction: column;
    color: #bbb;
    background-color: #312f2f;
    
}

.footer-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.footer-header div{
    padding: 20px;
    border-bottom: 1px solid #bbb;
}

.footer-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-content p{
    padding: 20px;
    width: 80%;
    
}


.footer-footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.footer-footer div{
   padding: 5px;
}

.footer-copyright{
    display: flex;
    flex-direction:row;
    justify-content: center;
    background-color: #292727;
    height: 80px;
 }

.footer-copyright div{
    padding: 5px;
   
 }

.margin-top-2px{
    margin-top: 2px;
}

.margin-top-5px{
    margin-top: 5px;
}

.margin-top-10px{
    margin-top: 10px;
}
.margin-left-5px{
    margin-left: 5px;
}

.margin-left-10px{
    margin-left: 10px;
}

.btn-blue{
    padding: 10px 20px 10px 20px;
    color: white;
    background-color:#01a3d4;
    border-radius: 50px;
    border:none;
    cursor: pointer;
    display: block;
    margin-top: 5px;
}
.btn-blue:hover{
    background-color:#0a97c2;
}
.btn-blue:active{
    background-color:#0d80a3;
}

.btn-red{
    padding: 10px 20px 10px 20px;
    color: white;
    background-color:rgba(151, 28, 28, 0.575);
    border-radius: 50px;
    border:none;
    cursor: pointer;
    display: block;
    margin-top: 5px;

}

.btn-red:hover{
    background-color:rgba(122, 23, 23, 0.575);
}

.btn-red:active{
    background-color:rgba(104, 23, 23, 0.575);
}

.user-info{
    flex-direction: column ;
    min-width: 50%;
    margin-top: 10px;
}

.card-bottom-border{
   border-bottom: 1px solid rgb(216, 210, 210);
   padding: 12px;
 
}

.border-melow{
    border: 1px solid rgb(216, 210, 210);
    padding: 12px;
}

.card-no-padding{
    border-radius: 5px;
    border-color: gray;
    background-color: white;
}


.card{
    border-radius: 5px;
    border-color: gray;
    padding: 12px;
    background-color: white;
}

.card-round{
    border-radius: 50px;
    border-color: gray;
    padding: 8px 15px 8px 15px;
    background-color: white;
}

.card-min-round{
    border-radius: 5px;
    border-color: gray;
    padding: 8px 15px 8px 15px;
    background-color: white;
}

.shadow{
    -moz-box-shadow: 0 0 1px #ccc;
    -webkit-box-shadow: 0 0 1px #ccc;
    box-shadow: 0 0 1px #ccc;
}

.column-item{
   margin-top: 5px;
   font-size:14px;
}

.column-item-center{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    font-size:14px;
    align-items: center;
    
 }


.row-item{
    margin-left: 5px;
    padding :20px;
}



.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.input-error{
    color: red;
    font-size: 12px;
}

.input-error-border{
    border: none;
    border: 2px solid red;
    color: red;
}

.hide{
    display: none;
}

.color-gray{
    color:gray;
    font-size: 14px;
}

.form-input-container{
    display: flex;
    flex-direction: row;
}

.form-input-container-item-small{
    width: 10%;
}

.form-input-container-item-big{
    width: 90%;
}

