.contactDetails{
    display: flex;
    flex-direction: column;
     padding-top: 10px;
   
}

.profile-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-header-info{
  display: flex;
  padding:7px;
  flex-direction: column;
  justify-content: space-between;
  
  
}
.profile-header-info-footer-container{
  display: flex;
  flex-direction: column;
}
.profile-header-info-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

}

.profile-header-info-footer-item{
  
  
}




.contact-appointments{
  max-height: 300px;
  overflow-y: scroll;
  flex-grow: 1;
}

.contactDetails-top-box{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.contactDetails .info{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap ;
  justify-content: space-around;
}

.contactDetails .info .left{
  padding: 5px;
  width: 27%;
  
}

.contactDetails .info .right{
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  
}

.contactDetails .profile-header .avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.contactDetails .item{
    margin-top: 5px;
    font-size: 14px;
}


.new-contact{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding:20px;
}

.new-contact .form{
   
    width: 90%;
   display: flex;
   flex-direction: row;
}

.form fieldset{
  flex-grow: 1;
  border: none;

  
}

.form-control {
  margin-top: 10px;
  
  
}

.form-control .btn-blue{
    width :50%;
    display: block;
    margin: auto;

}
  
  .form-control label {
    font-weight: bold;
    font-size: 12px;
}
  
  .form-control input {
   width: 100%;
   height: 40px;
   border-radius: 4px;
   border: 1px solid rgb(216, 210, 210);
   margin-top:5px;
  }
  
  .new-contact-icon{
    color:  #01a3d4;
  }
  
  .new-contact-column{
    display:flex ;
    flex-direction: column;
    justify-content: center;
    font-size: 15px !important;
    font-weight: bold;
    margin:0 7px 0 7px;
  }

  .new-contact-column p{
    margin: 0;
    padding: 0;
  }

  .modal-box-small{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 10px;
  }

  .modal-box{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 10px;
  }

  .modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .modal-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }

  .modal-title{
   
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    
 
  }
  
  .modal-content{
  
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    
  }

  .modal-content-footer{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    justify-content: center;
    
  }

  .modal-content-footer-3-btn{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    justify-content: space-between;
  }

  .two-btn-container{
      display: flex;
      flex-flow: row-reverse wrap;
      width: 60%;
  }

  .color-dark-gray{
    color: rgba(61, 60, 60, 0.973);
  }

  .modal-item  h2, h3, h4,h5{
    margin-top:0px;
    padding: 0;
    
  }

  .btn-trasparent{
    padding: 5px 10px 5px 10px;
    width: 80px;
    background-color:#f7f7f7;
    border-radius: 50px;
    border:1px solid black;
      cursor: pointer;

  }

  .btn-trasparent:hover{
    
    background-color:#e4e2e2;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
 
  }

 
 .form-control-row{
   display: flex;
   flex-direction: row;
   justify-content: center;
   
 }


  .modal-item-btn-blue{
    
    padding: 5px 10px 5px 10px;
    color: white;
    margin-left:2px ;
    width: 40%;
    background-color:#01a3d4;
    border-radius: 50px;
    border: none;
    cursor: pointer;

  }

  .modal-item-btn-red{
    
    width: 40%;
    padding: 5px 10px 5px 10px;
    background-color: white;
    border-radius: 50px;
    border: 1px solid red;
    
    cursor: pointer;
  }

  .modal-item-btn-blue:hover{
    background-color:#033f52;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}
.modal-item-btn-blue:active{
    background-color:#01141a;
}

.modal-item-btn-nobackground{
    
  width: 40%;
  padding: 5px 10px 5px 10px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 50px;
  
  cursor: pointer;
}

.modal-item-btn-nobackground:hover{
  transition: all .4s ease;
  color: rgb(248, 248, 248);
  -webkit-transition: all .4s ease;
  background-color:rgba(119, 119, 119, 0.938);
}


  .modal-item-btn-red:hover{
    transition: all .4s ease;
    color: white;
    -webkit-transition: all .4s ease;
    background-color:rgba(105, 17, 17, 0.938);
}

.modal-item-btn-red:active{
  background-color:rgba(82, 12, 12, 0.938);
}

  .modal-content-item{
    width: 100%;
    text-align: center;
  }

  

  .contact-container:active{
    
    background-color: rgb(180, 170, 170);
  }

  .contact-selected{
    background-color: red;
  }



  .react-calendar {
    font-family: sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    border: 1px solid rgb(221, 219, 219) !important;
    border-radius: 5%;
    padding: 5px;
    
  }

.react-calendar__tile {
    font-weight: initial;
    border-radius: 50%;
       
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 12px;
    color: rgb(15, 70, 15);
}

.react-calendar__tile--active {
    
    
}

.react-calendar__navigation {
   border-bottom: 1px solid rgb(233, 232, 232);
   padding-bottom: 5px;
}

.react-calendar__month-view__weekdays {
  
  font-style: normal !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-style: normal !important;
}

.react-calendar__tile:disabled {
  background-color:rgb(206, 206, 206) !important;
  font-weight: lighter;
  font-size: 11px;
}


.react-calendar__tile:enabled:focus {
  background-color:  #a4c3eb !important;
}

.react-calendar__tile,
.react-calendar__month-view__days__day {
    font-size: 12px;
}