.registerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.phoneContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
}

.register-form{
    width: 60%;
    padding: 10px;
   
}