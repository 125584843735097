.warning-container{
  
    display: flex;
    padding: 15px 2px 15px 10px;
    flex-direction: column;
    background-color: #e8ba92;
    color:#9c2409;
    width: 100%;
  }

  .warning-header{
    display: flex;
    flex-direction: row;
  }

  .warning-content{
    font-size: 12px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    padding-left: 22px;
    font-weight: normal;
  }

  .info-container{
  
    display: flex;
    padding: 15px 2px 15px 10px;
    flex-direction: column;
    background-color:#9fd3ca  ;
    color:#025547;
    width: 100%;
  }

  .info-header{
    display: flex;
    flex-direction: row;
  }

  .info-content{
    font-size: 12px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    padding-left: 22px;
    font-weight: normal;
  }

  .info-content p, .warning-content p{
      margin: 0;
  }

