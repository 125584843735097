

  
  .mainbox {
    
    margin: auto;
    height: 600px;
    width: 600px;
    position: relative;
  }
  
    .err {
    
      font-family: 'Nunito Sans', sans-serif;
      font-size: 11rem;
      position:absolute;
      left: 20%;
      top: 8%;
    }
  
  .far {
    position: absolute;
    font-size: 11rem;
    left: 42%;
    top: 8%;
    
  }
  
   .err2 {
     
      font-family: 'Nunito Sans', sans-serif;
      font-size: 11rem;
      position:absolute;
      left: 68%;
      top: 8%;
    }
  
  .msg {
      text-align: center;
      
      font-size: 1.6rem;
      position:absolute;
      left: 16%;
      top: 45%;
      width: 75%;
    }
  
  
  
  a:hover {
    text-decoration: underline;
  }